import { Action } from '@ngrx/store';
import { Fuel } from '../../models/fuel';
import { DispatchFuel } from '../../models/dispatchfuel';
import { DispatchRental } from '../../models/dispatchrental';
import { ServerResponseModel } from '../../models/serverresponsemodel';

export enum ActionTypes {
  GetTotalDebtsByEmployee = "[Debt] Get total debts by employee"
}

export class GetTotalDebtsByEmployeeAction implements Action {
  readonly type = ActionTypes.GetTotalDebtsByEmployee;
  constructor(public payload: ServerResponseModel) {}
}

export type Actions = GetTotalDebtsByEmployeeAction