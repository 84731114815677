import { Action } from '@ngrx/store';
import * as DateActions from "./date.actions";

const initialState: Date = new Date;
export function dateReducer(state: Date = initialState, action: DateActions.Actions) {
    switch (action.type) {
        case DateActions.ActionTypes.UpdateDate:
            return action.payload;
        default:
            return state;
    }
}
