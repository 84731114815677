import { Action } from '@ngrx/store';
import * as MenuBarActions from "./menubar.actions";
import { MenuBar } from '../../models/menubar';

const initialState: MenuBar = {
    title: "",
    showBack: false,
    menuItems: null
}

export function menuBarReducer(state: MenuBar = initialState, action: MenuBarActions.Actions) {
    switch (action.type) {
        case MenuBarActions.ActionTypes.UpdateMenuBar:
            return action.payload;
        default:
            return state;
    }
}