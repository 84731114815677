import { Action } from '@ngrx/store';
import { FuelService } from '../../services/fuel.service';
import { Loader } from '../../models/loader';

export enum ActionTypes {
    UpdateLoader = '[Loader] Update',
}

export class UpdateLoaderAction implements Action {
    readonly type = ActionTypes.UpdateLoader;
    constructor(public payload: boolean) { }
}

export type Actions = UpdateLoaderAction;