import { Action } from '@ngrx/store';
//import { ActionTypes } from './credentials.actions';
import * as CredentialsActions  from "./credentials.actions";
import { Credentials } from '../../models/credentials';

const initialState: Credentials = {
    Token: "",
    UserName: ""
}


export function credentialsReducer(state: Credentials[] = [initialState], action: CredentialsActions.Actions) {
    switch (action.type) {
        case CredentialsActions.ActionTypes.Login:
            return [...state,action.payload];
        default:
            return state;
    }
}