import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../../services/print.service';
import { Transaction } from '../../models/transaction';
import { PaymentsService } from '../../services/payments.service';
import { DebtsService } from '../../services/debts.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  invoiceIds: string[];
  driverName: string;
  bodyNumber: string;
  transactions = [];
  invoiceDetails: Promise<any>[];
  totalDebts: number;
  today = new Date();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  date = this.today.getFullYear()+'-'+(this.today.getMonth()+1)+'-'+this.today.getDate();

  constructor(route: ActivatedRoute,
    private printService: PrintService,
    private debtsService: DebtsService,
    private paymentService: PaymentsService) {
    this.invoiceIds = route.snapshot.params['invoiceIds']
      .split(',');
  }

  ngOnInit() {
    
    //this.printService.onDataReady()
    const idArray = [this.invoiceIds[0]];
    //this.getInvoiceDetails(this.invoiceIds[0])
    this.bodyNumber = this.invoiceIds[1];
    this.invoiceDetails = [];

    this.debtsService.getTotalDebtsByEmployee(+this.invoiceIds[0]).subscribe(
      serverResponse => {
        console.log(serverResponse);
        if (serverResponse) {
          this.totalDebts = parseInt(serverResponse.Data);

          this.invoiceDetails = idArray
              .map(id => 
                //return Promise<any>;
                this.getInvoiceDetails(id)
              );
        }
      });
    
  }

  getInvoiceDetails(invoiceId) {
    this.paymentService.getAllByDriverDateReceived(invoiceId).subscribe(
      payments => {
        for (var p of payments) {
          console.log(p);    
          let trans1 = {} as Transaction;
          trans1.Description = p.Description.replace("Payment for","");
          trans1.Amount = parseFloat(p.AmountPaid);
          this.transactions.push(trans1);      
          this.driverName = p.EmployeeName;
        }

        Promise.all(this.invoiceDetails)
      .then(() => this.printService.onDataReady());
      }
    );



    // let trans1 = {} as Transaction;
    // trans1.Description = "Payment rental  Sep-5-2020";
    // trans1.Amount = 12;
    // this.transactions.push(trans1);

    // let trans2 = {} as Transaction;
    // trans2.Description = "Rental Shortage Sep-5-2020";
    // trans2.Amount = 5;
    // this.transactions.push(trans2);
    
    // let trans3 = {} as Transaction;
    // trans3.Description = "Rental Shortage Sep-5-2020";
    // trans3.Amount = 5;
    // this.transactions.push(trans3);

    const amount = Math.floor((Math.random() * 100));
    // console.log(`id ${invoiceId}`);
    return new Promise(resolve =>
      setTimeout(() => resolve({amount}), 1000)
    );
  }

}
