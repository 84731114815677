import { Action } from '@ngrx/store';
import * as DiscountActions from "./discounts.actions";
import { Discount } from '../../models/discount';

export function discountsReducer(state: Discount[], action: DiscountActions.Actions) {
    switch (action.type) {
      case DiscountActions.ActionTypes.AddDiscount:
        break;
      case DiscountActions.ActionTypes.GetDiscount:
            let result = this.state.find(x => x.Id == action.payload);  
            return result;
        break;
      case DiscountActions.ActionTypes.LoadDiscount:
            return action.payload;
        break;
      default:
        return state;
    }
}