import { Action } from '@ngrx/store';
import * as RentalsActions from "./rentals.actions";
import { Fuel } from '../../models/fuel';
import { DispatchFuel } from '../../models/dispatchfuel';
import { ServerResponseModel } from '../../models/serverresponsemodel';

export function rentalsByDriverReducer(
         state: DispatchFuel[],
         action: RentalsActions.Actions
       ) {
         switch (action.type) {
             case RentalsActions.ActionTypes.LoadAllByDriver:
             return action.payload;
           default:
             return state;
         }
       }

       
export function unpaidRentsReducer(
  state: ServerResponseModel,
  action: RentalsActions.Actions
) {
  switch (action.type) {
    case RentalsActions.ActionTypes.GetUnpaidRents:
      return action.payload;
    default:
      return state;
  }
}