import { Action } from '@ngrx/store';
import * as DebtsActions from "./debts.actions";
import { Fuel } from '../../models/fuel';
import { DispatchFuel } from '../../models/dispatchfuel';
import { ServerResponseModel } from '../../models/serverresponsemodel';

export function totalDebtsByEmployeeReducer(
  state: ServerResponseModel,
  action: DebtsActions.Actions
) {
  switch (action.type) {
    case DebtsActions.ActionTypes.GetTotalDebtsByEmployee:
      return action.payload;
    default:
      return state;
  }
}